.react-calendar-heatmap { max-width: 600px }

.react-calendar-heatmap .color-empty-custom { fill: #424242; }

.react-calendar-heatmap .color-scale-0 { fill: #dcedc8; }
.react-calendar-heatmap .color-scale-1 { fill: #c5e1a5; }
.react-calendar-heatmap .color-scale-2 { fill: #aed581; }
.react-calendar-heatmap .color-scale-3 { fill: #9ccc65; }
.react-calendar-heatmap .color-scale-4 { fill: #8bc34a; }
.react-calendar-heatmap .color-scale-5 { fill: #7cb342; }
.react-calendar-heatmap .color-scale-6 { fill: #689f38; }
.react-calendar-heatmap .color-scale-7 { fill: #558b2f; }
.react-calendar-heatmap .color-scale-8 { fill: #33691e; }

.react-calendar-heatmap .react-calendar-heatmap-month-label { font-weight: 500; }
